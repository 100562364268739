<template>
  <div class="privacy">
    <div
      v-for="{ title, content } in $contentful.privacyPolicyTermsOfUse"
      :key="title"
      class="info-block"
    >
      <h2 class="block-title">
        {{ title }}
      </h2>

      <BRRichTextRenderer class="block-text" :document="content" />
    </div>
  </div>
</template>

<script>
import BRRichTextRenderer from '@/components/BRComponents/BRRichTextRenderer.vue'
export default {
  name: 'Privacy',
  components: {
    BRRichTextRenderer
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.privacy {
  @include container;

  padding: 38px 0;

  @include breakpoint-reverse(small) {
    padding: 4px 0;
  }
}

.info-block {
  margin-bottom: 104px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @include breakpoint-reverse(small) {
    margin-bottom: 69px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.block-title {
  margin-bottom: 42px;
  border-bottom: var(--default-border);
  padding-bottom: 35px;
  font-size: var(--font-display);
  text-align: center;

  @include breakpoint-reverse(small) {
    margin-bottom: 18px;
    padding-bottom: 23px;
    font-size: var(--font-h1);
    text-align: left;
  }
}

.block-text {
  max-width: 935px;
  font-size: var(--font-base);
  color: var(--secondary-text-color);

  &::v-deep h3 {
    margin-bottom: 20px;
    color: var(--text-color);

    &:not(:first-of-type) {
      margin-top: 58px;
    }

    @include breakpoint-reverse(small) {
      margin-bottom: 16px;
      font-size: var(--font-h3);

      &:not(:first-of-type) {
        margin-top: 38px;
      }
    }
  }

  &::v-deep p {
    margin-top: 17px;
    margin-bottom: 17px;

    &:empty {
      display: none;
    }

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    @include breakpoint-reverse(small) {
      margin-top: 13px;
      margin-bottom: 13px;
      font-size: var(--font-base);
    }
  }
}
</style>
